export const INTERFACE_TYPE = {
  LOG_WOOD_PO: 'log_wood_po',
  PROCESSED_WOOD_PO: 'processed_wood_po',
  MOVEMENT_ISSUE: 'movement_issue',
  MOVEMENT_RECEIPT: 'movement_receipt',
  BOM_TRANSACTION: 'bom_transaction',
  EXPORT_TRANSACTION: 'export_transaction',
  POST_BAKING_WASTE_WOOD_COUNT: 'post_baking_waste_wood_count',
  WASTE_WOOD_ORDER: 'waste_wood_order',
  WASTE_WOOD_ORDER_GRADING: 'waste_wood_order_grading',
  BOM_WASTE_WOOD: 'bom_waste_wood',
};
