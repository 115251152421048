import React from "react";
import PropTypes from "prop-types";
import { Button, Dimmer, Header, Icon, Loader } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { defaultPostBakingWasteWoodInputTrasactionDetail } from "../../constance/dataTypes";
import { GET, POST } from "../../utils/HttpClient";
import { URL_POST_BAKING } from "../../constance/urls";
import ErrorMessage from "../common/ErrorMessage";
import * as _ from "lodash";
import { formatComma } from "../../utils/stringFormat";
import { Toast } from "../../utils/Toast";
import { FONT_NORMAL_ANDROID, ALIGN_CENTER_ANDROID } from '../../constance/printers';
import moment from "moment";
import WasteWoodSizeAmountMapList from "./WasteWoodSizeAmountMapList";
import ConfirmModal from "../common/ConfirmModal";


export default function WoodSummary (props) {
  const {
    transaction,
    transactionDetail, 
    setTransactionDetail,
    onSearchWasteWood,
    onAdd,
    onEdit,
    onFinish,
  } = props;

  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [openDoneCheckModal, setOpenDoneCheckModal] = React.useState(false);
  const hasGroupDate = _.get(transactionDetail, 'post_baking_group_date', null)
  const isDone = _.get(transactionDetail, 'is_done', true)

  const handleAddWood = () => {
    if (!transactionDetail.post_baking_group_date) {
      onSearchWasteWood()
    } else {
      onAdd(transactionDetail.post_baking_group_date, transactionDetail.ovens)
    }
  }

  const fetchData = async () => {
    setLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(`${URL_POST_BAKING.POST_BAKING_WASTE_WOOD_INPUT_TRANSACTION}${transaction}/`);
      setTransactionDetail({
        ...defaultPostBakingWasteWoodInputTrasactionDetail,
        ...response.data
      });
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  const handlePrint = async () => {
    setLoading(true);
    setErrorMessages(null);
    try {
      const response = await POST(`${URL_POST_BAKING.POST_BAKING_WASTE_WOOD_INPUT_TRANSACTION}${transaction}/print/`)
      doPrintAndriod({...transactionDetail, ...response.data})
      Toast.success(`${t('print')} ${t('success')}`)
    } catch (error) {
      setErrorMessages(_.get(error, 'errorMessages', '') ? error.errorMessages : new String(error))
    } finally {
      setLoading(false);
    }
  }

  const doPrintAndriod = (detail) => {
    if (window.JSInterface) {
      let datas = []
  
      datas.push(ALIGN_CENTER_ANDROID)
      datas.push(FONT_NORMAL_ANDROID)      
  
      // title
      let titleRender = "รับเข้าไม้เสีย "
      datas.push(titleRender)

      // group date
      let groupDateRender = "วันที่ยุบกอง "
      const groupDate = moment(_.get(detail, 'post_baking_group_date', ''), 'YYYY-MM-DD')
      if (groupDate.isValid()) {
        groupDateRender += groupDate.format('DD/MM/YYYY')
      } else {
        groupDateRender += '-'
      }
      datas.push(groupDateRender)

      // input date
      let inputDateRender = "วันที่รับคืน "
      const inputDate = moment(_.get(detail, 'input_date', ''), 'YYYY-MM-DD')
      if (inputDate.isValid()) {
        inputDateRender += inputDate.format('DD/MM/YYYY')
      } else {
        inputDateRender += '-'
      }
      datas.push(inputDateRender)
      datas.push("")
      
      // wood list
      _.get(detail, 'wood_size_amount_map_list', []).filter(ws => _.get(ws, 'amount', 0) > 0).map(ws => {
        datas.push(`${ws.wood_code} : ${formatComma(ws.amount)}(${formatComma(ws.volume)}) ท่อน`)
      })
      datas.push("")
  
      datas.push(`รวม ${formatComma(_.get(detail, 'sum_amount', 0))} ท่อน ${formatComma(_.get(detail, 'sum_volume', 0))} ฟุต`)
  
      datas.push("")
      datas.push("")

      // print render
      let printRender = "พิมพ์วันที่ "
      const printDate = moment(_.get(detail, 'print_datetime', ''))
      if (printDate.isValid()) {
        printRender += printDate.format('DD/MM/YYYY HH:mm')
      } else {
        printRender += '-'
      }
      datas.push(printRender)
      datas.push(`โดย ${_.get(detail, 'print_user_name', '-')}`)


      window.JSInterface.printAndroid(datas.join('\r\n'))
    }
  }

  const handleDoneCheck = async () => {
    setLoading(true);
    setErrorMessages(null);
    try {
      await POST(`${URL_POST_BAKING.POST_BAKING_WASTE_WOOD_INPUT_DONE_CHECK}`, 
        { post_baking_group_date: transactionDetail.post_baking_group_date }
      );
      fetchData();
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  } 

  React.useEffect(() => {
    if (transaction) {
      fetchData();
    }
  }, [transaction])

  return (
    <div style={{ marginTop: '1rem' }}>
      <Header as='h4'>{t('barcodes.barcode_id')}: {transactionDetail.barcode}</Header>
      { transactionDetail.post_baking_group_date ? (
        <Header as='h4'>
          <div>
            {t('post_baking_waste_wood_count.group_date')}&nbsp;
            {moment(transactionDetail.post_baking_group_date, 'yyyy-MM-DD').format('DD/MM/yyyy')}
          </div>
          { transactionDetail.input_date && (
            <div>
              {t('post_baking_waste_wood_input.input_date')}&nbsp;
              {moment(transactionDetail.input_date, 'yyyy-MM-DD').format('DD/MM/yyyy')}
            </div>
            ) }
        </Header>
      ) : null }
      <Dimmer inverted active={loading}>
        <Loader inverted content={t('loading')}/>
      </Dimmer>
      <ErrorMessage message={errorMessages}/>

      <WasteWoodSizeAmountMapList
        dataList={transactionDetail.wood_size_amount_map_list}
        onEdit={onEdit}
        fetchData={fetchData}/>

      { hasGroupDate && (
        <div>
          { isDone ? (
            <span><Icon name='check' color='green'/> {t('post_baking_waste_wood_input.done_check')}</span>
          ) : (
            <Button
              color='yellow'
              icon='edit outline'
              size='small'
              content={t('post_baking_waste_wood_input.done_check')}
              onClick={() => setOpenDoneCheckModal(true)}/>
          )}
        </div>
      ) }

      <div style={{ marginTop: '2rem' }}>
        { (!hasGroupDate || !isDone) && <Button color='green' icon='add' content={t('add')} onClick={handleAddWood}/> }
        <Button primary icon='print' disabled={!transactionDetail.wood_size_amount_map_list.length} content={t('print')} onClick={() => handlePrint()}/>
        <Button content={t('finish')} onClick={onFinish}/>
      </div>

      <ConfirmModal
        open={openDoneCheckModal}
        onClose={() => setOpenDoneCheckModal(false)}
        title={t('post_baking_waste_wood_input.done_check')}
        content={`${t('workflows.confirmation_content')}: [${t('post_baking_waste_wood_input.done_check')}]?`}
        onConfirm={handleDoneCheck}/>
    </div>
  )
}


WoodSummary.propTypes = {
  transaction: PropTypes.string,
  onSearchWasteWood: PropTypes.func,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onFinish: PropTypes.func,
}

WoodSummary.defaultProps = {
  transaction: '',
  onSearchWasteWood: () => null,
  onAdd: () => null,
  onEdit: () => null,
  onFinish: () => null,
}