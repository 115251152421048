import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Button, Container, Form, Header, List, Popup } from 'semantic-ui-react';
import CustomTable from '../../components/common/CustomTable';
import MainHeader, { MENU_INTERFACE_AX } from '../../components/common/MainHeader';
import { URL_INTERFACE_AX } from '../../constance/urls';
import { POST } from '../../utils/HttpClient';
import { ellipsisString } from '../../utils/stringFormat';
import { Toast } from '../../utils/Toast';
import * as _ from 'lodash';


export default function InterfaceAXExportTransactionView() {

  const { t } = useTranslation();
  const [date, setDate] = React.useState('');
  const tableRef = React.useRef()

  const columns = React.useMemo(() => [
    {
      Header: t('date'),
      accessor: 'created',
      Cell: ({ row }) => new Date(row.original.created).toLocaleString('en-GB')
    },
    {
      Header: t('export_transactions.sale_order_no'),
      accessor: 'sale_order_no',
    },
    {
      Header: t('export_transactions.container_no'),
      accessor: 'container_no',
    },
    {
      Header: t('export_transactions.customer_code'),
      accessor: 'customer_code',
    },
    {
      Header: t('export_transactions.wood_size'),
      Cell: ({ row }) => {
        const woodSizeList = _.get(row.original, 'export_wood_sizes', [])
        return (
          <Popup
            content={
              <List bulleted>
                { woodSizeList.map(e => (
                  <List.Item>{e}</List.Item>
                ))}
              </List>
            }
            inverted
            trigger={<div>{ellipsisString(woodSizeList.join(', '), 26)}</div>}
            position='bottom left'
          />
        )
      }
    },
    {
      Header: () => null,
      accessor: 'id',
      Cell: ({row}) => !row.original.interface_progressing ? (
        <Button
          basic
          fluid
          primary
          content='Interface AX'
          onClick={() => handleDoInterfaceAX(row.original.id)}
        />
      ) : (
        <Button
          disabled
          primary
          fluid
          basic
          content={`${t('progressing')}...`}
        />
      )
    }
  ], [])

  const handleDoInterfaceAX = async (id) => {
    if (!tableRef.current) {
      return;
    }
    tableRef.current.setIsLoading(true);
    tableRef.current.setErrorMessages(null);
    try {
      const response = await POST(`${URL_INTERFACE_AX.EXPORT_TRANSACTION}${id}/do_interface/`)
      tableRef.current.refetch();
      Toast.success(t('success'))
    } catch (error) {
      tableRef.current.setErrorMessages(error.errorMessages);
    } finally {
      tableRef.current.setIsLoading(false);
    }
  }

  const dateFilterComponent = (
    <Form.Field
      inline
      label={t('searchs.fields.date')}
      locale="en-US"
      format="DD/MM/YYYY"
      showToday={true}
      placeholder={t('searchs.placeholders.date')}
      control={SemanticDatepicker}
      onChange={(_, data) => {
        if (data.value) {
          setDate(moment(data.value).format('YYYY-MM-DD'))
        } else {
          setDate('')
        }
      }}
    />
  )

  return (
		<div>
			<MainHeader activeItem={MENU_INTERFACE_AX}/>
			<Container style={{ marginTop: '7em' }}>
        <Header as='h2'>
          {t('menus.interface_ax')}: {t('menus.interface_ax_menus.export_transaction')}
        </Header>
        <CustomTable
          ref={tableRef}
          fetchUrl={URL_INTERFACE_AX.EXPORT_TRANSACTION}
          columns={columns}
          showSearch
          showSize
          showPagination
          extraFilter={dateFilterComponent}
          extraFilterParams={{date: date}}
        />
			</Container>
		</div>
  )
}